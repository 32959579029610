import { Link } from "react-router-dom";
import "./Demo.css";
import ReactGA from "react-ga4";

const Demo = ({ loggedIn = false }) => {
  ReactGA.send({ hitType: "pageview", page: "/demo", title: "Demo" });

  return (
    <div className="demo_container">
      <h2 className="demo_header header_font demo_container_item">Demo</h2>
      <iframe
        src="https://www.youtube.com/embed/buLTakhXCP8"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        className="demo_container_item demo_iframe"
      ></iframe>
      <Link to="/app/dashboard">
        <button className="cta_button_homepage_inner demo_container_item demo_cta">
          {(loggedIn as boolean) ? "Dashboard" : "Get Started for Free"}
        </button>
      </Link>
    </div>
  );
};

export default Demo;
