import React, { useEffect, useState } from "react";
import { CircleLoader } from "react-spinners";
import { SpinnerCircular } from "spinners-react";
import "./AddArticleModal.css";
import $ from "jquery";
import Constants from "./Constants";

type ModalProps = {
  show: boolean;
  onClose: any;
  children: string;
};

function AddArticleModal({ show, onClose, children }: ModalProps) {
  const [links, setLinks] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  var saving_loading: JQuery.jqXHR<any>;

  const close = (e: any) => {
    setLinks([]);
    saving_loading !== undefined &&
      saving_loading !== null &&
      saving_loading.abort();
    setLoading(false);
    onClose && onClose(e);
  };

  const parseLinks = (e: any) => {
    const val = e.target.value;
    const regex =
      /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;
    const matches = val.match(regex);
    setLinks(matches || []);
  };

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  let [color, setColor] = useState("#000000");

  const renderLoadingMenu = () => {
    return (
      <div className="loadingBarModal">
        <SpinnerCircular color="000000" />
      </div>
    );
  };

  const formatLinks = () => {
    var formatted: any[] = [];
    console.log(links);
    for (var link in links) {
      formatted.push(<li className="found_link_item">{links[link]}</li>);
    }
    return formatted;
  };

  const saveLinks = () => {
    setLoading(true);
    saving_loading = $.ajax({
      type: "POST",
      url: Constants.endpoint_base + "api/parse_manual",
      data: {
        user_id: window.localStorage.getItem("key") || "",
        formatted_links: links.join(","),
      },
      success: function (data) {
        close(null);
      },
      error: function (err) {
        setError(err["error_msg"]);
      },
    });
  };

  if (show) {
    return (
      <div className="add-article-modal" id="modal">
        <div className="add-article-modal-custom">
          <div className="add-article-header-wrapper">
            <h1 className="add-article-header">Add Articles</h1>
          </div>
          <p className="add-article-modal-description">
            Paste your links in the area below, we find the links to generate AI
            headlines and descriptions.
          </p>
          <textarea
            className="form-control add-article-modal-textarea"
            placeholder="https://nyt.com, https://wsj.com, ..."
            onChange={parseLinks}
            rows={10}
          ></textarea>
          {links !== null && links.length > 0 ? (
            <div>
              <p className="add-article-modal-description">Found Links:</p>
              <ul>{formatLinks()}</ul>
            </div>
          ) : (
            ""
          )}
          {error !== "" ? error : ""}
          <button className="dismiss_button" onClick={close}>
            Cancel
          </button>
          <button
            className="save_links_button"
            onClick={saveLinks}
            disabled={
              links !== undefined && links !== null && links.length === 0
            }
          >
            {loading ? (
              <SpinnerCircular color="ffffff" className="save_spinner" />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default AddArticleModal;
