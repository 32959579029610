import { Link } from "react-router-dom";
import "./Contact.css";
import ReactGA from "react-ga4";

const Contact = () => {
    ReactGA.send({ hitType: "pageview", page: "/contact", title: "Contact" });

    return (
        <div className="contact_container">
            <h1 className="header_font">Contact Us</h1>
            <p className="center_align">
                We're here to answer any questions about pricing and the app. Send us an email or contact us on twitter.
                Please subject press inquiries with [PRESS INQUIRY]</p>
            <div className="contact_us_options">
                <ul>
                    <li className="center_align"><Link to="mailto:team@redenseai.com" className="contact_us_email no_underline">Email: team@redenseai.com</Link></li>
                    <li className="center_align"><Link to="https://twitter.com/redenseai" className="contact_us_email no_underline">Twitter: twitter.com/redenseai</Link></li>
                </ul>
            </div>
        </div>
    )
}

export default Contact;