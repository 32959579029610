import { Link } from "react-router-dom";
import BlueCheck from "./blue-check.svg.png";
import "./PaymentsSuccess.css";

const PaymentsSuccess = ({
  title = "Welcome to Redense Premium",
  subtitle = "Thank you for your payment. You're all set",
}) => {
  return (
    <div className="payments_success_container">
      <div className="payments_success_inner_container">
        <img src={BlueCheck} width={100} />
        <h1 className="text_center">{title}</h1>
        <p className="text_center">{subtitle}</p>
        <div className="back_to_dashboard_button">
          <Link to="/app/dashboard">
            <button className="build_button">Back to Dashboard</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PaymentsSuccess;
