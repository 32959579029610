import { Link } from "react-router-dom";
import Sample from "./sample.png";
import Dismiss from "./dismiss.png";
import "./Homepage.css";
import { useState } from "react";
import ReactGA from "react-ga4";
import { Event } from "./Tracking";
import Demo from "./Demo";

const Homepage = ({ loggedIn = false }) => {
  const [modal, setModal] = useState(false);
  ReactGA.send({ hitType: "pageview", page: "/", title: "Homepage" });

  return (
    <div className="homepage">
      <div className="homepage_hero">
        <div className="homepage_container">
          <div className="hero_container_left">
            <h1 className="hero_banner_text header_font">
              Newsletters Made Easy
            </h1>
            <p className="hero_banner_description">
              Create engaging newsletters in minutes with AI. Simply enter the
              links you want to include in your newsletter and our AI will
              generate a headline, description, and build a newsletter for you.
            </p>
            <div className="call_to_action_containers">
              <div className="cta_major">
                <Link to="/app/dashboard">
                  <button className="cta_button_homepage_inner">
                    {(loggedIn as boolean) ? "Dashboard" : "Get Started"}
                  </button>
                </Link>
              </div>
              <div
                className="cta_minor"
                onClick={() => {
                  setModal(true);
                }}
              >
                See a Demo →
              </div>
            </div>
          </div>
          <div className="homepage_image"></div>
        </div>
      </div>
      <div className="homepage_explainer">
        <h3 className="header_font">How it works</h3>
        <div className="homepage_explainer_subsection_container">
          <div className="homepage_explainer_subsection">
            <h4 className="header_font">1. Submit Links</h4>
            <p>
              Just send links to the articles you want to include to
              receiving@parse.redense.com or use our{" "}
              <Link
                className="outbound_link"
                to="https://chrome.google.com/webstore/detail/redensesync/ddiiojhbcemahbljmfanffooohcdkadg"
              >
                Chrome Extension
              </Link>
            </p>
          </div>
          <div className="homepage_explainer_subsection">
            <h4 className="header_font">2. Get AI Generated Assets</h4>
            <p>
              Our service leverages Google PaLM, GPT3.5, and GPT4 to parse your
              links to create an AI generated headline and description.
            </p>
          </div>
          <div className="homepage_explainer_subsection">
            <h4 className="header_font">3. Build your Newsletter</h4>
            <p>
              Build your newsletter at the end of the day. Our AI will write a
              newsletter that lets you get started.
            </p>
          </div>
        </div>
      </div>
      <div className="homepage_demo">
        <Demo />
      </div>
      <div className="homepage_testimonials">
        <h3 className="header_font">Testimonials</h3>
        <div className="homepage_testimonial_container">
          <div className="homepage_testimonial_card">
            <h4 className="header_font">
              Working terrifically! Really enjoying the updates, etc... Great
              work! Happy to be using this platform for 'AI Music Newsletter'.
            </h4>
            <p className="testimonial_signature">- Jeff</p>
          </div>
        </div>
      </div>
      <div className="get_started">
        <h3 className="header_font">Get Started</h3>
        <p>Signing up takes less than a minute. No credit card required</p>
        <div className="call_to_action_containers">
          <div className="cta_major">
            <Link to="/app/dashboard">
              <button className="cta_button_homepage_inner">
                {(loggedIn as boolean) ? "Dashboard" : "Get Started for Free"}
              </button>
            </Link>
          </div>
          <div
            className="cta_minor"
            onClick={() => {
              setModal(true);
            }}
          >
            See a Demo →
          </div>
        </div>
      </div>
      <div className={"overlaid_video_background" + (!modal ? " hidden" : "")}>
        <div
          className="overlaid_dismiss_button pointer"
          onClick={() => {
            setModal(false);
          }}
        >
          <img src={Dismiss} width="36px" height="36px" />
        </div>
        <div className="overlaid_video_asset">
          <iframe
            src="https://www.youtube.com/embed/DVkmB3RCMSg"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
