//@ts-nocheck

import { Link } from "react-router-dom";
import "./Pricing.css";
import { useEffect, useState } from "react";
import Constants from "./Constants";
import $ from "jquery";

const Pricing = ({ loggedin = false, currentlySubscribed = "free" }) => {
  const [userState, setUserStatus] = useState(
    window.localStorage.getItem("subscription_level") || "FREE"
  );

  function getAccountStatus() {
    $.ajax({
      type: "GET",
      url: Constants.endpoint_base + "users/status",
      data: {
        uuid: window.localStorage.getItem("key") || "",
      },
      success: function (data) {
        setUserStatus(data["subscription_level"] || "FREE");
        window.localStorage.setItem(
          "subscription_level",
          data["subscription_level"]
        );
      },
      error: function (err) {},
    });
  }

  useEffect(() => {
    getAccountStatus();
  }, []);

  return (
    <div className="pricing-container">
      <div className="pricing-inner-container">
        <h1 className="header_font text_center font_large">Pricing</h1>
        <p className="text_center">
          Choose the plan that's right for you. We offer multiple pricing plans
          to fit your needs, whether you're looking for a monthly or annual
          subscription. Monthly plans are perfect for those who want the
          flexibility to cancel at any time, while annual plans offer a
          significant discount and can save you money in the long run. You may
          cancel the monthly plan at anytime.
        </p>
        <div className="pricing-options-container">
          <div className="pricing-option">
            <h4 className="text_center">FREE</h4>
            <p className="text_center">
              <span className="price">$0</span>
              <br />
              /month
            </p>
            <ul className="features-list">
              <li>❌ Up to 2 newsletters a month</li>
              <li>❌ Up to 5 articles per newsletter</li>
              <li>❌ Limited support when available</li>
            </ul>
          </div>
          <div className="pricing-option">
            <div className="limited_time_offer">Limited Time Offer</div>

            <h4 className="text_center">PREMIUM</h4>
            <p className="text_center">
              <span className="price strikethrough">$15</span>
              <span className="price"> $12</span>
              <br />
              /month
            </p>
            <ul className="features-list">
              <li>✅ Unlimited newsletters per month</li>
              <li>✅ Parse unlimited articles per day</li>
              <li>✅ Up to 20 articles per newsletter</li>
              <li>✅ Beta test new features before they launch</li>
              <li>✅ Priority Support: Receive support in 24 hours</li>
            </ul>
            <div className="cta_major">
              <Link to="/app/dashboard/subscribe">
                <button className="cta_button_homepage_inner">
                  Subscribe Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
