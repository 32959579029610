import "./FAQ.css"
import Collapsible from 'react-collapsible';
import ReactGA from "react-ga4";

function FAQ() {

    ReactGA.send({ hitType: "pageview", page: "/faq", title: "FAQ" });

    return (
        <div className="faq-component">
            <div className="faq-container">
                <h3 className="faq-header header_font">Frequently Asked Questions</h3>
                <Collapsible trigger="· What is Redense AI?">
                <p className="faq-inner">
                    Redense AI is the easiest way to The easiest way to build a newsletter.
                </p>
                <p className="faq-inner">
                    Create engaging newsletters in minutes with AI. Simply enter the links you want to include in your newsletter and our AI will generate a headline, description, and build a newsletter for you. Redense AI is currently in an open beta.
                </p>
                </Collapsible>
                <Collapsible trigger="· What's available during open beta?">
                <p className="faq-inner">
                    Almost all of the features for Redense AI will be available during the open beta. We're excited for you to join us on this journey and thank you so much for signing up and testing Redense during our initial trial period.
                </p>
                </Collapsible>
                <Collapsible trigger="· How do I format the emails?">
                <p className="faq-inner">
                    Make sure you are sending emails to receiving@parse.redenseai.com, sending to any other email might lead to your article <b>not being processed</b>
                </p>
                <p className="faq-inner">
                    Make sure you are including the link the <b>body</b> of the email instead of the subject. The subject can be left blank and will not be processed.
                </p>
                </Collapsible>
                <Collapsible trigger="· Why are my articles not showing up?">
                <p className="faq-inner">
                    There are a few reasons that your article might not show up. Currently your account is linked to the email that you used to sign up. Please verify that you're sending emails from that specific address or <b>your articles will not be processed</b>
                </p>
                <p className="faq-inner">
                    Make sure you are sending emails to receiving@parse.redenseai.com, sending to any other email might lead to your article <b>not being processed</b>
                </p>
                <p className="faq-inner">
                    Make sure you are including the link the <b>body</b> of the email instead of the subject. The subject can be left blank and will not be processed.
                </p>
                <p className="faq-inner">
                    We are always exploring new ways of making compiling articles faster and easier.
                </p>
                </Collapsible>
                <Collapsible trigger="· Why did my articles disappear?">
                <p className="faq-inner">
                    We initially built Redense to compile daily newsletters. Therefore articles from the previous day or past few days will not appear on your dashboard. Because of this articles might disappear, but don't worry. They aren't deleted, they are simply archieved. We are exploring ways of looking at historic articles.
                </p>
                </Collapsible>
                <Collapsible trigger="· Will Redense eventually cost money?">
                <p className="faq-inner">
                    We are currently exploring ways of running our service reliably and profitably long term. This might be in the form of a monthly subscription or advertising. This might be subject to change in the future.
                </p>
                </Collapsible>
                <Collapsible trigger="· Do you have customer support?">
                <p className="faq-inner">
                    We are currently a subsidiary of Delta X Technology LLC which also owns the brand Tech Pastry. You can email us at team@redenseai.com
                </p>
                </Collapsible>
            </div>

        </div>
    )
}
export default FAQ;