// @ts-nocheck

import React, { useEffect, useRef, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import NotValidTab from "./NotValidTab";
import NotLinkedinTab from "./NotLinkedinTab";
import ScrapingDisplay from "./ScrapingDisplay";
import Login from "./Login";
import $ from "jquery";
import Dashboard from "./Dashboard";
import Logo from "./RedenseAILogo.png";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ReactGA from "react-ga4";
import { Routes, Route, Link } from "react-router-dom";
import FAQ from "./FAQ";
import { BrowserRouter } from "react-router-dom";
import HomePage from "./Homepage";
import Homepage from "./Homepage";
import Privacy from "./Privacy";
import Demo from "./Demo";
import Contact from "./Contact";
import ErrorPage from "./404";
import Pricing from "./Pricing";
import Burger from "./components/Burger/Burger";
import Menu from "./components/Menu/Menu";
import DashboardRouter from "./DashboardRouter";
import Constants from "./Constants";

ReactGA.initialize("G-WN67T4CQ82");

function App() {
  const [noValidTab, setNoValidTab] = useState(true);
  const [notLinkedinTab, setNotLinkedinTab] = useState(true);
  const [failedScript, setFailedScript] = useState(true);
  const [savedData, setSavedData] = useState({});
  const [scraping, setScraping] = useState(false);
  const [doneScraping, setDoneScraping] = useState(false);
  const [userStatus, setUserStatus] = useState(
    window.localStorage.getItem("subscription_level") || "FREE"
  );
  const [open, setOpen] = useState(false);
  const node = useRef();
  const [loggedin, setLoggedin] = useState(
    window.localStorage.getItem("key") !== null &&
      window.localStorage.getItem("key") !== ""
  );
  const [userUUID, setUserUUID] = useState(
    window.localStorage.getItem("key") || ""
  );
  const [dataSaved, setDataSaved] = useState(false);
  const email = window.localStorage.getItem("email");

  useEffect(() => {}, [loggedin, userUUID]);

  const updateLoggedin = (loggedin: boolean, uuid: string) => {
    setLoggedin(loggedin);
    setUserUUID(uuid);
    window.localStorage.setItem("key", uuid);
    console.log(uuid);
  };

  const checkLoggedin = () => {
    const uuid = window.localStorage.getItem("key") || "";
    setLoggedin(uuid !== null && uuid !== "");
    setUserUUID(uuid);
  };

  const toggleAuth = () => {
    if (loggedin) {
      window.localStorage.setItem("key", "");
      window.localStorage.setItem("subscrition_level", "FREE");
      setLoggedin(false);
      setUserUUID("");
    }
  };

  function getAccountStatus() {
    $.ajax({
      type: "GET",
      url: Constants.endpoint_base + "users/status",
      data: {
        uuid: window.localStorage.getItem("key") || "",
      },
      success: function (data) {
        setUserStatus(data["subscription_level"] || "FREE");
        window.localStorage.setItem(
          "subscription_level",
          data["subscription_level"]
        );
      },
      error: function (err) {},
    });
  }

  useEffect(() => {
    getAccountStatus();
  }, []);

  return (
    <BrowserRouter>
      <GoogleOAuthProvider clientId="80742291414-1iuht0idjpjrpn42lglq7lqs08c23vhl.apps.googleusercontent.com">
        <div className="App">
          <header className="redense_toolbar">
            <div className="toolbar_container">
              <Link to="/" className="no_underline">
                <p className="redense_logo">
                  <img src={Logo} height="30px" />
                  <div
                    className={`premium_badge ${
                      userStatus === "FREE" ? "hidden" : ""
                    }`}
                  >
                    {userStatus === "FREE" ? "" : "Premium"}
                  </div>
                </p>
              </Link>
              <div className="separator"></div>
              <div className="hide_on_desktop">
                <Burger open={open} setOpen={setOpen} />
                <Menu open={open} setOpen={setOpen} />
              </div>
              <Link
                to="https://chrome.google.com/webstore/detail/redensesync/ddiiojhbcemahbljmfanffooohcdkadg"
                className="no_underline hide_on_mobile"
              >
                <div className="toolbar_item signin_disclaimer">
                  Chrome Extension
                </div>
              </Link>
              <Link
                to={
                  userStatus === "FREE"
                    ? "/pricing"
                    : "/app/dashboard/payments/already_subscribed"
                }
                className="no_underline hide_on_mobile"
              >
                <div className="toolbar_item signin_disclaimer">
                  {userStatus === "FREE" ? "Pricing" : "Your Subscription"}
                </div>
              </Link>
              <Link to="faq" className="no_underline hide_on_mobile">
                <div className="toolbar_item signin_disclaimer">FAQ</div>
              </Link>

              <div className="signin_disclaimer hide_on_mobile">
                {!loggedin ? (
                  <Link className="drop_style pointer" to="/app/dashboard">
                    Get Started
                  </Link>
                ) : (
                  <p className="toolbar_item signout_item" onClick={toggleAuth}>
                    <div>Sign Out</div>
                    <div className="email_part">{email}</div>
                  </p>
                )}
              </div>
            </div>
          </header>
          <div className={"App-header"}>
            <Routes>
              <Route path="/" element={<Homepage loggedIn={loggedin} />} />
              <Route
                path="/app/dashboard*"
                element={
                  !loggedin ? (
                    <Login loggedinCallback={updateLoggedin} />
                  ) : (
                    <DashboardRouter />
                  )
                }
              />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/demo" element={<Demo loggedIn={loggedin} />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </div>
          <footer className="redense_footer">
            <div className="footer_container">
              <div className="ownership_bar">
                <p>
                  © 2023 Delta X Technology LLC
                  <br />
                  Redense AI is a Delta X Technology LLC product
                </p>
                <Link
                  to="mailto:team@redenseai.com"
                  className="no_underline footer_item font_bold"
                >
                  Contact us: team@redenseai.com
                </Link>
              </div>
              <div className="separator"></div>
              <div className="menu_link_bar">
                <Link to="/demo" className="no_underline footer_item">
                  Demo
                </Link>
                <Link
                  to="https://chrome.google.com/webstore/detail/redensesync/ddiiojhbcemahbljmfanffooohcdkadg"
                  className="no_underline footer_item"
                >
                  Chrome Extension
                </Link>
              </div>
              <div className="menu_link_bar">
                <Link to="/app/dashboard" className="no_underline footer_item">
                  Get Started
                </Link>
                <Link to="/faq" className="no_underline footer_item">
                  FAQ
                </Link>
                <Link to="/privacy" className="no_underline footer_item">
                  Privacy Policy
                </Link>
                <Link
                  to={
                    userStatus === "FREE"
                      ? "/pricing"
                      : "/app/dashboard/payments/already_subscribed"
                  }
                  className="no_underline footer_item"
                >
                  {userStatus === "FREE" ? "Pricing" : "Your Subscription"}
                </Link>
              </div>
            </div>
          </footer>
        </div>
      </GoogleOAuthProvider>
    </BrowserRouter>
  );
}

export default App;
