import { Link } from "react-router-dom";
import './404.css';
import './Homepage.css';
import ReactGA from "react-ga4";

const ErrorPage = () => {
    ReactGA.send({ hitType: "pageview", page: "/404", title: "404 Error" });

    return (
        <div className="errorpage_container">
            <div className='errorpage_text_container'>
                <h3>Oh no! It looks like the page you were looking for is nowhere to be found. Maybe you mistyped the URL? Or maybe it's been deleted?</h3>
                <h3>Whatever the reason, don't despair! In the meantime, here's a funny cat video to help you forget about your troubles. </h3>
                <iframe src="https://www.youtube.com/embed/pOmu0LtcI6Y" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                <br/>
                <div className='signin_disclaimer'>
                    <button className="cta_button_homepage_inner">
                        <Link className='drop_style pointer' to="/">Homepage</Link>    
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage;