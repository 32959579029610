import { ChangeEvent, MouseEvent, useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import $ from "jquery";
import "./Login.css";
import { SpinnerCircular } from "spinners-react";
import { GoogleLogin } from "@react-oauth/google";
import jwt from "jwt-decode"; // import dependency
import ReactGA from "react-ga4";
import { Event } from "./Tracking";
import Constants from "./Constants";

function Login({ loggedinCallback = (loggedin: boolean, uuid: string) => {} }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [signupFlow, setSignupFlow] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const updateEmail = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target !== null) {
      setEmail(event.target.value);
    }
  };

  const updatePassword = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target !== null) {
      setPassword(event.target.value);
    }
  };

  const updateConfirmPassword = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target !== null) {
      setConfirmPassword(event.target.value);
    }
  };

  const responseGoogle = (response: any) => {
    console.log(response);
  };

  const authenticate = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Max-Age": "1000",
    };

    if (!signupFlow) {
      console.log("signupFlow");
      setError("");
      setLoading(true);
      $.ajax({
        type: "GET",
        url: Constants.endpoint_base + "users/signin-redense-user",
        data: {
          password: password,
          username: email,
        },
        success: function (data) {
          console.log(data);
          setLoading(false);
          if (data["success"] === true) {
            window.localStorage.setItem("key", data["uuid"]);
            window.localStorage.setItem("email", data["email"]);
            loggedinCallback(true, data["uuid"] as string);
          } else {
            setError(data["error_msg"]);
          }
          Event("authentication", "logged_in", "password_authentication");
        },
        error: function (err) {
          setLoading(false);
          setError("There was an error, please try again.");
        },
      });
    } else {
      if (confirmPassword === password) {
        console.log("Started signup");
        setError("");
        const uuid = uuidv4();
        setLoading(true);
        $.ajax({
          type: "GET",
          url: Constants.endpoint_base + "users/create-redense-user",
          data: {
            password: password,
            username: email,
            uuid: uuid,
          },
          success: function (data) {
            console.log(data);
            setLoading(false);
            if (data["success"] === true) {
              window.localStorage.setItem("key", uuid);
              window.localStorage.setItem("email", data["email"]);
              loggedinCallback(true, uuid);
            } else {
              setError(data["error_msg"]);
            }
            Event("authentication", "signed_up", "password_signup");
          },
          error: function (err) {
            setLoading(false);
            setError("Unknown error, please try again");
          },
        });
      } else {
        setError("Passwords don't match");
      }
    }
  };

  const authenticate_gmail = (jwt: string) => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Max-Age": "1000",
    };

    if (!signupFlow) {
      console.log("signupFlow");
      setError("");
      setLoading(true);
      $.ajax({
        type: "GET",
        url: Constants.endpoint_base + "users/signin-redense-gmail-user",
        data: {
          jwt: jwt,
        },
        success: function (data) {
          console.log(data);
          setLoading(false);
          if (data["success"] === true) {
            window.localStorage.setItem("key", data["uuid"]);
            window.localStorage.setItem("email", data["email"]);
            loggedinCallback(true, data["uuid"] as string);
          } else {
            setError(data["error_msg"]);
          }
          Event("authentication", "signed_up", "google_sso_signed_up");
        },
        error: function (err) {
          setLoading(false);
          setError("There was an error, please try again.");
        },
      });
    } else {
      console.log("Started signup");
      setError("");
      const uuid = uuidv4();
      setLoading(true);
      $.ajax({
        type: "GET",
        url: Constants.endpoint_base + "users/create-redense-gmail-user",
        data: {
          jwt: jwt,
          uuid: uuid,
        },
        success: function (data) {
          console.log(data);
          setLoading(false);
          if (data["success"] === true) {
            window.localStorage.setItem("key", uuid);
            window.localStorage.setItem("email", data["email"]);
            loggedinCallback(true, uuid);
          } else {
            setError(data["error_msg"]);
          }
          Event("authentication", "signed_up", "google_sso_logged_in");
        },
        error: function (err) {
          setLoading(false);
          setError("Unknown error, please try again");
        },
      });
    }
  };

  if (signupFlow) {
    ReactGA.send({ hitType: "pageview", page: "/signup", title: "Signup" });
  } else {
    ReactGA.send({ hitType: "pageview", page: "/login", title: "Login" });
  }

  return (
    <div className="auth_container">
      {signupFlow && (
        <div className="signup_wrapper">
          <div className="signup_login_wrapper">
            <span className="login_header header_font">Signup</span>
            <input
              className="textfield"
              onChange={updateEmail}
              value={email}
              placeholder="email"
            />
            <input
              className="textfield"
              onChange={updatePassword}
              type="password"
              value={password}
              placeholder="password"
            />
            <input
              className="textfield"
              onChange={updateConfirmPassword}
              type="password"
              value={confirmPassword}
              placeholder="confirm password"
            />
          </div>
          <p className="error_msg">{error}</p>
          <div className="cta_button">
            <button className="cta_button_inner" onClick={authenticate}>
              {loading ? (
                <div className="loadingSpinnerLogin">
                  <SpinnerCircular color="ffffff" size="30px" />
                </div>
              ) : (
                "Signup"
              )}
            </button>
          </div>
          <div className="sso_logins">
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                // TODO: Move the JWT parsing to the backend
                const credential: string = credentialResponse.credential || "";
                authenticate_gmail(credential);
              }}
              shape="pill"
              onError={() => {
                console.log("Login Failed");
              }}
            />
          </div>
          <p className="disclaimer">
            By signing up you agree to receive occasional updates and marketing
            emails from Delta X Technology LLC
          </p>
          <a
            className="transition_to_signup"
            onClick={() => {
              setError("");
              setSignupFlow(false);
            }}
          >
            Already have an account? Login Instead
          </a>
        </div>
      )}
      {!signupFlow && (
        <div className="login_wrapper">
          <div className="login_inner_wrapper">
            <span className="login_header header_font">Login</span>
            <input
              className="textfield"
              onChange={updateEmail}
              value={email}
              placeholder="email"
            />
            <input
              className="textfield"
              onChange={updatePassword}
              type="password"
              value={password}
              placeholder="password"
            />
          </div>
          <p className="error_msg">{error}</p>
          <div className="cta_button">
            <button className="cta_button_inner" onClick={authenticate}>
              {loading ? (
                <div className="loadingSpinnerLogin">
                  <SpinnerCircular color="ffffff" size="30px" />
                </div>
              ) : (
                "Login"
              )}
            </button>
          </div>
          <div className="sso_logins">
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                // TODO: Move the JWT parsing to the backend
                const credential: string = credentialResponse.credential || "";
                authenticate_gmail(credential);
              }}
              shape="pill"
              onError={() => {
                console.log("Login Failed");
              }}
            />
          </div>
          <a
            className="transition_to_signup"
            onClick={() => {
              setError("");
              setSignupFlow(true);
            }}
          >
            Don't have an account? Signup Instead
          </a>
        </div>
      )}
    </div>
  );
}

export default Login;
