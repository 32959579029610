import PaymentsSuccess from "./PaymentsSuccess";

const AlreadySubscribed = () => {
  return (
    <PaymentsSuccess
      title="You're already subscribed"
      subtitle="Email team@redenseai.com with the subject [Cancel Subscription] and your email to cancel."
    />
  );
};

export default AlreadySubscribed;
