import { Routes, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import PricingSelect from "./PricingSelect";
import PriceSelectWrapper from "./PricingSelect";
import PaymentsSuccess from "./PaymentsSuccess";
import AlreadySubscribed from "./SubscriptionAlreadySubscribed";

const DashboardRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />}></Route>
      <Route path="/subscribe" element={<PriceSelectWrapper />}></Route>
      <Route
        path="/payments/already_subscribed"
        element={<AlreadySubscribed />}
      ></Route>
      <Route path="/payment/success" element={<PaymentsSuccess />}></Route>
    </Routes>
  );
};

export default DashboardRouter;
