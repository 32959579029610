// @ts-nocheck
import { useParams } from "react-router-dom";
import "./PricingSelect.css";
import { useEffect, useState } from "react";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import $ from "jquery";
import { SpinnerCircular } from "spinners-react";
import Constants from "./Constants";

const stripePromise = loadStripe(Constants.stripe_key);

const PriceSelectWrapper = () => {
  return (
    <Elements stripe={stripePromise}>
      <PricingSelect />
    </Elements>
  );
};

const PricingSelect = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [values, setValues] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [userStatus, setUserStatus] = useState(
    window.localStorage.getItem("subscription_level") || "FREE"
  );

  function getAccountStatus() {
    $.ajax({
      type: "GET",
      url: Constants.endpoint_base + "users/status",
      data: {
        uuid: window.localStorage.getItem("key") || "",
      },
      success: function (data) {
        // console.log(data);
        setUserStatus(data["subscription_level"] || "FREE");
        window.localStorage.setItem(
          "subscription_level",
          data["subscription_level"]
        );
        if (data["subscription_level"] !== "FREE") {
          window.location = "/app/dashboard/payments/already_subscribed";
        }
      },
      error: function (err) {},
    });
  }

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error !== undefined) {
      setError(
        "There was an error with this card. Please reenter your card number and try again."
      );
      setLoading(false);
      return;
    }
    const { id } = paymentMethod;
    $.ajax({
      type: "POST",
      url: Constants.endpoint_base + "users/payments/subscribe",
      data: {
        uuid: window.localStorage.getItem("key") || "",
        address: values,
        payment_id: id,
      },
      success: function (data) {
        setLoading(false);
        if (data["success"] === true) {
          console.log(data["response"]);
          window.location = "/app/dashboard/payment/success";
        }
      },
      error: function (err) {
        setError(err["error_msg"]);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    getAccountStatus();
  }, []);

  return (
    <div className="subscribe-container">
      <div className="subscribe-inner-container">
        <h1 className="header_font text_center font_large">Checkout</h1>
        <p className="text_center">
          You're seconds away from priority support, unlimited newsletter,
          unlimited article parsing daily, and more articles per newsletters.
          Cancel anytime.
        </p>
        <div className="checkout_data_entry_panel">
          <form onSubmit={handleSubmit} className="billing_address_card">
            <h4 className="pricing_headers">Billing Address</h4>
            <div className="billing_input_container">
              <div className="form-group">
                <label htmlFor="address_1">Address 1</label>
                <input
                  type="text"
                  name="address_1"
                  className="form-control"
                  id="address_1"
                  aria-describedby="emailHelp"
                  placeholder="1940 Broadway Ave"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="address_2">Address 2</label>
                <input
                  type="text"
                  name="address_2"
                  className="form-control"
                  id="address_2"
                  aria-describedby="address 2"
                  placeholder=" "
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  name="city"
                  className="form-control"
                  id="city"
                  aria-describedby="city"
                  placeholder="New York City"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  name="state"
                  className="form-control"
                  id="state"
                  aria-describedby="state"
                  placeholder="NY"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="zipcode">Zipcode</label>
                <input
                  type="number"
                  name="zipcode"
                  className="form-control"
                  id="zipcode"
                  aria-describedby="zipcode"
                  placeholder="10001"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="cc_card">
              <h4>Credit Card Information</h4>
              <div className="billing_input_container">
                <CardElement />
              </div>
            </div>
            <button type="submit" className="build_button">
              {loading ? (
                <div className="loadingSpinnerLogin">
                  <SpinnerCircular color="ffffff" size="30px" />
                </div>
              ) : (
                "Purchase Subscription"
              )}
            </button>
            <p className="error_msg">{error}</p>
          </form>
          <div className="checkout_subtotal_preview">
            <h4>Subtotal</h4>
            <div className="checkout_subtotal">
              <p>Redense AI Premium Subscription</p>
              <div className="separator"></div>
              <p className="strikethrough">$15</p>
              <p className=""> $12/month</p>
            </div>
            <p className="value_prop_line">What you get:</p>
            <p className="value_prop_line">Unlimited newsletters per month</p>
            <p className="value_prop_line">Up to 20 articles per newsletter</p>
            <p className="value_prop_line">
              Parse unlimited articles every day
            </p>
            <p className="value_prop_line">Priority Support</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceSelectWrapper;
