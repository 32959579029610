import React, { useEffect, useState } from "react";
import { CircleLoader } from "react-spinners";
import { SpinnerCircular } from "spinners-react";
import "./Modal.css";

type ModalProps = {
  show: boolean;
  onClose: any;
  children: string;
  title: string;
  description: string;
};

function Modal({ show, onClose, children, title, description }: ModalProps) {
  const close = (e: any) => {
    onClose && onClose(e);
  };

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  let [color, setColor] = useState("#000000");

  const renderLoadingMenu = () => {
    return (
      <div className="loadingBarModal">
        <SpinnerCircular color="000000" />
      </div>
    );
  };

  if (show) {
    return (
      <div className="modalBackdrop" id="modal">
        <div className="modal-custom">
          <h6 className="generated_headline">Your Generated Newsletter</h6>
          <p className="newsletter_instructions">
            Copy and paste this into your preferred email client
          </p>
          {children.length === 0 ? renderLoadingMenu() : <div></div>}
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: children }}
          ></div>
          <div className="actions">
            <button className="dismiss_button" onClick={close}>
              Close
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default Modal;
