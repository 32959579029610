import React from "react";
import { bool } from "prop-types";
import { StyledMenu } from "./Menu.styled";
import { Link } from "react-router-dom";

const Menu = ({ open, setOpen }) => {
  return (
    <StyledMenu open={open}>
      <Link
        to="/"
        onClick={() => {
          setOpen(false);
        }}
      >
        Home
      </Link>
      <Link
        to="https://chrome.google.com/webstore/detail/redensesync/ddiiojhbcemahbljmfanffooohcdkadg"
        onClick={() => {
          setOpen(false);
        }}
      >
        Chrome Extension
      </Link>
      <Link
        to="/faq"
        onClick={() => {
          setOpen(false);
        }}
      >
        FAQ
      </Link>
      <Link
        to="/pricing"
        onClick={() => {
          setOpen(false);
        }}
      >
        Pricing
      </Link>
      <Link
        to="/app/dashboard"
        onClick={() => {
          setOpen(false);
        }}
      >
        Get Started
      </Link>
    </StyledMenu>
  );
};

Menu.propTypes = {
  open: bool.isRequired,
};

export default Menu;
