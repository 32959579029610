// @ts-nocheck
import "@coreui/coreui/dist/css/coreui.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useEffect, useState } from "react";
import $ from "jquery";
import "./Dashboard.css";
import CircleLoader from "react-spinners/CircleLoader";
import MoonLoader from "react-spinners/MoonLoader";
import { SpinnerCircular } from "spinners-react";
import Modal from "./Modal";
import { Event } from "./Tracking";
import Form from "react-bootstrap/Form";
import ReactGA from "react-ga4";

// import TextField from "@mui/material/TextField";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import dayjs, { Dayjs } from "dayjs";
import { FadeLoader } from "react-spinners";
// import { DateValidationError, LocalizationProvider } from '@mui/x-date-pickers'
// import { DatePicker } from '@mui/x-date-pickers'
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { PickerChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types';
import { debounce } from "lodash";
import AddArticleModal from "./AddArticleModal";
import Constants from "./Constants";

function Dashboard() {
  const date = new Date();
  const today =
    date.getFullYear() +
    "/" +
    (Number(date.getMonth()) + 1) +
    "/" +
    date.getDate();
  const dDate =
    Number(date.getMonth()) +
    1 +
    "/" +
    date.getDate() +
    "/" +
    date.getFullYear();

  const [currentDate, setCurrentDate] = useState(today);
  const [displayDate, setDisplayCurrentDate] = useState(dDate);
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [lookbackLoading, setLookbackLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showArticleModal, setShowArticleModal] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [lookbackWindow, setLookbackWindow] = useState("");
  const [userStatus, setUserStatus] = useState("FREE");
  const [generatedNewslettersCount, setGeneratedNewslettersCount] = useState(0);
  const [startDate, setStartDate] = React.useState<Dayjs | null>(
    dayjs(new Date()).subtract(0, "day")
  );
  const [endDate, setEndDate] = React.useState<Dayjs | null>(
    dayjs(new Date()).subtract(0, "day")
  );

  const [newsletterText, setNewsletterText] = useState("");
  var xhr, xhr_customized: JQuery.jqXHR<any>;
  var interval;
  var email = window.localStorage.getItem("email") || "";
  var customized_timeout = undefined;
  ReactGA.send({
    hitType: "pageview",
    page: "/app/dashboard",
    title: "Dashboard",
  });

  function resetDateString(date: dayjs) {
    date.hour(0);
    date.minute(0);
    date.second(0);
    date.millisecond(0);
    return date;
  }

  function implementLookbackWindow(now: Date) {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    if (lookbackWindow == "7_days") {
      now.setDate(now.getDate() - 7);
    } else if (lookbackWindow == "14_days") {
      now.setDate(now.getDate() - 14);
    } else if (lookbackWindow == "28_days") {
      now.setDate(now.getDate() - 28);
    } else if (lookbackWindow == "all") {
      now.setDate(now.getDate() - 1250);
    }
    return now;
  }

  function getAccountStatus() {
    $.ajax({
      type: "GET",
      url: Constants.endpoint_base + "users/status",
      data: {
        uuid: window.localStorage.getItem("key") || "",
      },
      success: function (data) {
        setUserStatus(data["subscription_level"] || "FREE");
        setGeneratedNewslettersCount(data["created_newsletters"] || 0);
      },
      error: function (err) {},
    });
  }

  function retrieveData() {
    var now = new Date();
    implementLookbackWindow(now);
    var nowTime = now.getTime();
    let startOfDay = nowTime - (nowTime % 86400000);
    let endDate = startOfDay + 86400000;
    var offset = new Date().getTimezoneOffset(); // Returns the offset in minutes
    offset = offset * 60 * 1000;

    if (xhr !== undefined && xhr !== null) {
      xhr.abort();
    }

    xhr = $.ajax({
      type: "POST",
      url: Constants.endpoint_base + "api/articles/" + currentDate,
      data: {
        uuid: window.localStorage.getItem("key") || "",
        lowerbound: startOfDay + offset,
        upperbound: endDate + offset,
      },
      success: function (data) {
        window.setTimeout(retrieveDataWithCustomized, 60 * 1_000);
        if (data["response"]) {
          setAllData(data["response"]);
        } else {
          setAllData([]);
        }
        setIsLoading(false);
      },
      error: function (err) {
        setIsLoading(false);
      },
    });
  }

  const retrieveDataWithCustomized = debounce(() => {
    var now = startDate.toDate();
    implementLookbackWindow(now);
    var nowTime = now.getTime();
    let startOfDay = nowTime - (nowTime % 86400000);

    var end = endDate.toDate();
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    var offset = new Date().getTimezoneOffset(); // Returns the offset in minutes
    offset = offset * 60 * 1000;

    console.log(now);
    console.log(end);

    if (customized_timeout !== undefined) {
      window.clearTimeout(customized_timeout);
    }

    if (xhr_customized !== undefined && xhr_customized !== null) {
      xhr_customized.abort();
    }

    xhr_customized = $.ajax({
      type: "POST",
      url: Constants.endpoint_base + "api/articles/" + currentDate,
      data: {
        uuid: window.localStorage.getItem("key") || "",
        lowerbound: startOfDay + offset,
        upperbound: endDate + offset,
      },
      success: function (data) {
        customized_timeout = window.setTimeout(
          retrieveDataWithCustomized,
          60 * 1_000
        );
        if (data["response"]) {
          setAllData(data["response"]);
        } else {
          setAllData([]);
        }
        setIsLoading(false);
        setLookbackLoading(false);
      },
      error: function (err) {
        setIsLoading(false);
        setLookbackLoading(false);
      },
    });
  });

  function validateStartDate(
    date: Date | null,
    context: PickerChangeHandlerContext<DateValidationError>
  ) {
    const today = new Date();
    if (date > endDate) {
      setEndDate(null);
    }

    if (date > today) {
      setStartDate(dayjs(new Date()));
    } else {
      setStartDate(dayjs(date));
    }
    if (startDate !== null && endDate !== null) {
      retrieveDataWithCustomized();
    }
  }

  function validateEndDate(
    date: Date | null,
    context: PickerChangeHandlerContext<DateValidationError>
  ) {
    const today = new Date();
    if (date < startDate) {
      setStartDate(null);
    }

    if (date > today) {
      setEndDate(dayjs(new Date()));
    } else {
      setEndDate(dayjs(date));
    }

    if (startDate !== null && endDate !== null) {
      retrieveDataWithCustomized();
    }
  }

  function removeArticle(e: React.MouseEvent<HTMLButtonElement>) {
    const response = window.confirm(
      "Are you sure you want to delete this article?"
    );
    if (response) {
      var data = Object.assign([], allData);
      const dataref = e.currentTarget.getAttribute("data-ref");
      for (var idx = 0; idx < data.length; idx++) {
        if (data[idx]["id"] === Number(dataref)) {
          data.splice(idx, 1);
          break;
        }
      }
      setAllData(data as []);
      // TODO: Call endpoint to delete

      xhr = $.ajax({
        type: "POST",
        url: Constants.endpoint_base + "api/remove",
        data: {
          user_id: window.localStorage.getItem("key") || "",
          id: dataref,
        },
        success: function (data) {
          if (data["response"]) {
            // setAllData(data['response']);
          } else {
            // setAllData([]);
          }
        },
        error: function (err) {},
      });
    }
  }

  function buildDisplayedInfoDashboard(
    data: { [id: string]: string }[]
  ): any[] {
    let elements: any[] = [];

    console.log(data);
    for (var idx = 0; idx < data.length; idx++) {
      const date = new Date(data[idx]["created_date"] + "Z");
      elements.push(
        <div className="link_item_group" key={data[idx]["url"]}>
          <div className="details_group">
            <p className="date_string">
              Parsed at {date.toLocaleDateString()} {date.toLocaleTimeString()}
            </p>
            <h6 className="url">
              <div>
                <a
                  href={data[idx]["url"]}
                  target="_blank"
                  className="article_link_btn"
                >
                  Article Link
                </a>
              </div>
              <span
                className="remove_btn"
                onClick={removeArticle}
                data-ref={data[idx]["id"]}
              >
                Remove
              </span>
            </h6>
          </div>
          <h3 className="ai_headline">{data[idx]["ai_headline"]}</h3>
        </div>
      );
    }

    return elements;
  }

  function buildNewsletter() {
    setShowModal(true);
    var now = new Date();
    implementLookbackWindow(now);
    var nowTime = now.getTime();
    let startOfDay = nowTime - (nowTime % 86400000);
    let endDate = startOfDay + 86400000;
    var offset = new Date().getTimezoneOffset(); // Returns the offset in minutes
    offset = offset * 60 * 1_000;

    $.ajax({
      type: "POST",
      url: Constants.endpoint_base + "api/build_newsletter/" + currentDate,
      data: {
        uuid: window.localStorage.getItem("key") || "",
        lowerbound: startOfDay + offset,
        upperbound: endDate + offset,
      },
      success: function (data) {
        if (data["newsletter_text"]) {
          setNewsletterText(data["joined_summary"]);
          setModalBody(data["joined_summary"]);
        } else {
          setNewsletterText("");
        }
        Event("generate", "build_newsletter", "web_ui");
      },
      error: function (err) {},
    });
  }

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  let [color, setColor] = useState("#ffffff");

  const renderLoadingMenu = () => {
    return (
      <div className="loadingBar">
        <CircleLoader
          color={color}
          loading={isLoading}
          cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  };

  const closeAddArticleModal = () => {
    setShowArticleModal(true);
  };

  const getMaximumNumberOfArticles = () => {
    if (userStatus === "FREE") return 5;
    return 20;
  };

  const cantGenerated = () => {
    if (userStatus === "FREE" && generatedNewslettersCount > 2) return true;
    else return false;
  };

  const renderData = () => {
    return (
      <div className="rendered_data">
        <div className="lookback_window_wrapper">
          <div className="add_cta">
            {
              <button
                className="add_articles_button"
                onClick={() => {
                  setShowArticleModal(true);
                }}
              >
                Add Articles
              </button>
            }
          </div>
          <div className="lookback_window_toggle ui_window">
            <p>Show data from </p>
            <Form.Select
              aria-label="Default select example"
              className="lookback_select"
              onChange={(event) => {
                setLookbackWindow(event.target.value);
                setLookbackLoading(true);
              }}
            >
              <option value="24_hours">Last 24 hours</option>
              <option value="7_days">Last 7 days</option>
              <option value="14_days">Last 14 days</option>
              <option value="28_days">Last 28 days</option>
              <option value="all">All time</option>
            </Form.Select>
          </div>
        </div>
        <p className="loading_disclaimer">
          {lookbackLoading ? "Loading..." : ""}
        </p>
        {buildDisplayedInfoDashboard(allData)}
        <div className="center_align_spinner">
          <SpinnerCircular color="ffffff" />
          <p className="spinner_disclosure">Listening for new articles</p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (isLoading) {
      // return () => setIsLoading(false);
    }
  }, [showModal]);
  useEffect(() => {
    retrieveDataWithCustomized();
  }, [lookbackWindow]);

  if (isLoading) {
    retrieveDataWithCustomized();
    getAccountStatus();
  }

  return (
    <div className="center_align">
      <div className="dashboard_menu_bar">
        <p className="current_date_display">Welcome back</p>
        <p className="dashboard_subtitle">
          Press build when you're ready to create your AI generated newsletter.
        </p>
        {/* <div className='time-window-container'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={validateStartDate}
                            className='time-window-input'
                            />
                        <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={validateEndDate}
                            className='time-window-input'
                            />
                    </LocalizationProvider>
                </div> */}
      </div>
      <div className="data_plane">
        <div className="disclosure_plane_wrapper">
          <div className="disclosure_plane ui_window">
            <h4>Instructions</h4>
            <p className="disclosure_p">
              To start adding articles for your newsletter just send an email
              with the link to the article to:
            </p>
            <input
              placeholder="receiving@parse.redenseai.com"
              disabled
              className="disclosure_input"
              value="receiving@parse.redenseai.com"
            />
            <p className="disclosure_p">
              Only emails from the email below are accepted. Make sure the link
              is in the <b>body</b> of the email. A simpler email will lead to
              better performance
            </p>
            <input
              placeholder={email}
              disabled
              className="disclosure_input"
              value={email}
            />
          </div>
          <p className="ai_disclaimer">
            Content is generated by AI and may not be accurate
          </p>
          <div className="build_cta">
            {
              <button
                className="build_button"
                onClick={buildNewsletter}
                disabled={
                  allData.length === 0 ||
                  allData.length > getMaximumNumberOfArticles() ||
                  cantGenerated()
                }
              >
                Build Newsletter ✨
              </button>
            }
            <p className="articles_error">
              {allData.length > getMaximumNumberOfArticles()
                ? `You have too many articles, the maximum number for ${userStatus.toLocaleLowerCase()} users is ${getMaximumNumberOfArticles()}. Please remove some before building your newsletter`
                : cantGenerated()
                ? "You already generated the maximum number of newsletter this month. " +
                  "Signup for RedenseAI Premium to generated unlimited newsletters every month"
                : ""}
            </p>
          </div>
        </div>
        {renderData()}
      </div>
      <Modal
        show={showModal}
        description="Copy and paste this into your preferred email client"
        title="Your Generated Newsletter"
        onClose={() => {
          setShowModal(false);
        }}
        children={modalBody}
      />
      <AddArticleModal
        show={showArticleModal}
        onClose={() => {
          setShowArticleModal(false);
        }}
        children={modalBody}
      />
    </div>
  );
}

export default Dashboard;
